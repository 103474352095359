import React from 'react';
import { ModalContainer, ModalContainerProps } from '../Modal';

export type SidebarContainerProps = ModalContainerProps;

export const SidebarContainer = React.forwardRef<HTMLDivElement, SidebarContainerProps>(
  (props, ref) => {
    return (
      <ModalContainer
        width={props.wFull ? undefined : '300px'}
        position={'left'}
        animate={'slide'}
        hFull
        {...props}
        ref={ref}
      />
    );
  }
);
