export const checkImgSrcLoaded = (src) => {
  if (!src) {
    return false;
  }

  const img = new window.Image();

  img.src = src;

  if (!img.complete) {
    return false;
  }

  // However, they do have two very useful properties: naturalWidth and
  // naturalHeight. These give the true size of the image. If it failed
  // to load, either of these should be zero.
  if (img.naturalWidth === 0) {
    return false;
  }

  return true;
};
