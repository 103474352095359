'use client';

import { TextArea as BaseTextArea } from 'react-aria-components';
import { useTextAreaAutoSizer } from '#/packages/ui';
export type { TextAreaProps } from 'react-aria-components';

export const TextArea = (props) => {
  const ref = useTextAreaAutoSizer({ value: props.value });

  return <BaseTextArea key={props.name} ref={ref} rows={1} data-gramm={'false'} {...props} />;
};
