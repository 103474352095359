import { getTriggerOffset } from './getTriggerOffset';

export const getPointerShift = (
  trigger: HTMLElement | null,
  position = 0,
  opts: { orientation: 'horizontal' | 'vertical'; direction: 'right' | 'left' | 'top' | 'bottom' }
) => {
  const triggerOffset = getTriggerOffset(trigger, opts);

  let shift = 0;

  if (opts.direction === 'left' || opts.direction === 'top') {
    shift = triggerOffset.start + triggerOffset.size / 2 - position;
  } else if (opts.direction === 'right' || opts.direction === 'bottom') {
    shift = triggerOffset.start + triggerOffset.size / 2 - position;
  }

  return shift;
};
