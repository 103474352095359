import { classNames } from '../../../utils/classNames';
import { ButtonColor, ButtonWeight } from '../types';

export function getButtonViewClasses(props: {
  color?: ButtonColor;
  weight?: ButtonWeight;
  plain?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  noBorder?: boolean;
}) {
  const { color, weight = 'solid', isDisabled, isActive, plain, noBorder } = props;

  const baseClasses = classNames(
    'text-black',
    { 'opacity-50': isDisabled },
    { 'border border-transparent': !noBorder }
  );

  if (plain || !color) {
    return classNames(baseClasses);
  }

  if (color === 'primary') {
    return classNames(
      baseClasses,
      '[aria-selected="true"]:bg-primary-3 data-[aria-selected="true"]:border-primary-3 data-[aria-selected="true"]:text-black',
      {
        'bg-primary-2 text-black': weight === 'solid',
        'border-primary-2': weight === 'solid' || weight === 'outline',
        'bg-white text-black': weight === 'outline' || weight === 'ghost',
        'hover:bg-primary-3 hover:border-primary-3 hover:text-black':
          weight !== 'inline' && !isDisabled
      }
    );
  }

  if (color === 'secondary') {
    return classNames(
      baseClasses,
      isActive
        ? {
            'bg-black/80 border-black/80 text-white': weight === 'solid',
            'bg-black/5 border-black text-black': weight !== 'solid' && weight !== 'inline'
          }
        : {
            'bg-black text-white': weight === 'solid',
            'bg-white/20 text-black': weight !== 'solid',
            'border-black': weight === 'solid' || weight === 'outline',
            'hover:bg-black/80 hover:border-black/80 hover:text-white':
              weight === 'solid' && !isDisabled,
            'hover:bg-black/5 hover:border-black hover:text-black':
              weight !== 'solid' && weight !== 'inline' && !isDisabled
          }
    );
  }

  if (color === 'tertiary') {
    return classNames(
      baseClasses,
      'aria-selected:bg-black/80 aria-selected:border-black/10 aria-selected:text-white',
      isActive
        ? 'bg-black/80 border-black/10 text-white'
        : {
            'bg-neutral-1': weight === 'solid',
            'border-black/10': weight === 'solid' || weight === 'outline',
            'hover:bg-neutral-2 hover:border-black/10': weight !== 'inline' && !isDisabled
          }
    );
  }

  if (color === 'neutral') {
    return classNames(
      baseClasses,
      isActive
        ? 'bg-black/10 border-black/10 text-black'
        : {
            'bg-black/5 text-black': weight === 'solid',
            'border-black/5': weight === 'solid',
            'border-black/10': weight === 'outline',
            // 'text-gray-600': weight !== 'solid',
            'hover:bg-black/10 hover:border-black/10 hover:text-black':
              weight !== 'inline' && !isDisabled
          }
    );
  }

  if (color === 'destructive') {
    return classNames(
      baseClasses,
      isActive
        ? {
            'bg-red-500/80 border-red-500/80 text-white': weight === 'solid',
            'bg-red-500/5 border-red-500 text-red-500': weight !== 'solid' && weight !== 'inline'
          }
        : {
            'bg-red-500 text-white': weight === 'solid',
            'bg-white/20 text-red-500': weight !== 'solid',
            'border-red-500': weight === 'solid' || weight === 'outline',
            'hover:bg-red-500/80 hover:border-red-500/80 hover:text-white':
              weight === 'solid' && !isDisabled,
            'hover:bg-red-500/5 hover:border-red-500 hover:text-red-500':
              weight !== 'solid' && weight !== 'inline' && !isDisabled
          }
    );
  }

  if (color == 'default') {
    if (isDisabled) {
      return 'bg-gray-100 text-gray-900 ring-1 ring-inset ring-gray-300';
    } else {
      return 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50';
    }
  }
}
