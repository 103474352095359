import React, { ReactNode } from 'react';
import { Modal as BaseModal } from 'react-aria-components';
import { tv } from 'tailwind-variants';
import type { ModalAnimate, ModalPosition, ModalSize } from './types';
import { ComposeAnimationVariantConfig, classNames, composeAnimationVariants } from '../../utils';

export interface ModalContainerProps {
  size?: ModalSize;
  position?: ModalPosition;
  animate?: ModalAnimate;
  hFull?: boolean;
  wFull?: boolean;
  width?: string;
  className?: string;
  children: ReactNode;
}

export const ModalContainer = React.forwardRef<HTMLDivElement, ModalContainerProps>(
  (props, ref) => {
    const {
      size,
      position = 'center',
      animate = 'fade',
      width,
      wFull,
      hFull,
      className,
      ...rest
    } = props;

    return (
      <BaseModal
        {...rest}
        ref={ref}
        style={{ width }}
        className={({ isEntering, isExiting }) =>
          classNames(
            modalContainerClassNames({
              size,
              wFull,
              hFull,
              position,
              animate,
              isEntering: Boolean(animate) && isEntering,
              isExiting: Boolean(animate) && isExiting
            }),
            className
          )
        }
      />
    );
  }
);

const slideAnimation: Record<ModalPosition, ComposeAnimationVariantConfig | null> = {
  left: ['slide-in-from-left', 'slide-out-to-left'],
  right: ['slide-in-from-right', 'slide-out-to-right'],
  top: ['slide-in-from-top', 'slide-out-to-top'],
  bottom: ['slide-in-from-bottom', 'slide-out-to-bottom'],
  center: null
};

const modalContainerClassNames = tv({
  base: 'absolute overflow-hidden align-middle text-left max-w-full max-h-full transition-none',
  variants: {
    size: {
      sm: 'sm:max-w-sm',
      md: 'sm:max-w-md',
      lg: 'sm:max-w-lg',
      xl: 'sm:max-w-xl',
      '2xl': 'sm:max-w-2xl',
      '3xl': 'sm:max-w-3xl',
      '4xl': 'sm:max-w-4xl',
      '5xl': 'sm:max-w-5xl',
      '6xl': 'sm:max-w-6xl'
    },
    wFull: {
      true: 'w-full'
    },
    hFull: {
      true: 'h-full'
    },
    position: {
      left: 'left-0 top-1/2 -translate-y-1/2',
      right: 'right-0 top-1/2 -translate-y-1/2',
      top: 'top-0 left-1/2 -translate-x-1/2',
      bottom: 'bottom-0 left-1/2 -translate-x-1/2'
      // center: 'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
    },
    animate: {
      slide: '',
      fade: '',
      zoom: ''
    },
    isEntering: {
      true: 'animate-in duration-300 ease-out'
    },
    isExiting: { true: 'animate-out duration-150 ease-in' }
  },
  compoundVariants: [
    {
      position: ['right', 'left'],
      hFull: true,
      className: 'top-0 translate-y-0'
    },
    {
      position: ['top', 'bottom'],
      wFull: true,
      className: 'left-0 translate-x-0'
    },
    ...composeAnimationVariants(
      {
        animate: 'fade'
      },
      ['fade-in', 'fade-out']
    ),
    ...composeAnimationVariants(
      {
        animate: 'zoom'
      },
      ['zoom-in', 'zoom-out']
    ),
    ...Object.keys(slideAnimation)
      .map((position) =>
        composeAnimationVariants(
          {
            animate: 'slide',
            position: position
          },
          slideAnimation[position as ModalPosition] || []
        )
      )
      .flat()
  ]
});
