'use client';
import { useInView } from 'framer-motion';
import React, { ChangeEvent, forwardRef, useLayoutEffect, useMemo, useRef, useState } from 'react';
import type { SelectOption } from './types';
import { useSelectItems } from './useSelectItems';
import { classNames } from '../../utils';
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuProps,
  DropdownMenuSearchField,
  DropdownProps
} from '../Dropdown';
import { DropdownMenuLabel } from '../Dropdown/DropdownMenuLabel';
import type { MenuKey } from '../Menu';
import { IMenuItem } from '../Menu';
import { useMenuSearch } from '../Menu/MenuSearch/useMenuSearch';
import { TextInputProps } from '../TextInput';

export interface SelectProps extends Omit<DropdownProps, 'children' | 'label'> {
  multiple?: boolean;
  withSearch?: boolean;
  label?:
    | React.ReactNode
    | ((selectedProperties: IMenuItem[], state: { open: boolean }) => React.ReactNode);
  placeholder?: React.ReactNode;
  searchPlaceholder?: TextInputProps['placeholder'];
  value?: MenuKey | MenuKey[];
  options: SelectOption[];
  disabledKeys?: MenuKey[];
  optionsFooter?: React.ReactNode;
  matchOptions?: (option: SelectOption[], filter: string) => MenuKey[];
  onChange?: DropdownMenuProps['onAction'];
  onSelectionChange?: DropdownMenuProps['onSelectionChange'];
  onFilterChange?: (value: string) => void;
  MenuSectionHeaderComponent?: DropdownMenuProps['MenuSectionHeaderComponent'];
  dropdownMenuClassName?: string;
  loadMore?: () => void;
}

export const Select: React.FC<
  SelectProps & Pick<DropdownMenuProps, 'emptyList'> & { hiddenKeys?: MenuKey[] }
> = forwardRef((props, ref) => {
  const {
    label,
    value,
    options,
    hiddenKeys,
    multiple,
    withSearch,
    placeholder,
    optionsFooter,
    disabledKeys: initialDisabledKeys,
    matchOptions,
    onChange,
    onSelectionChange,
    MenuSectionHeaderComponent,
    emptyList,
    searchPlaceholder,
    onOpenChange,
    onFilterChange: onFilterChangeProp,
    loadMore,
    dropdownMenuClassName,
    ...rest
  } = props;

  const items = useSelectItems({ options });
  const [open, setOpen] = useState(Boolean(props.open));
  const popoverRef = useRef<HTMLDivElement>(null);
  const endRef = useRef<HTMLDivElement>(null);

  const endOfList = useInView(endRef, { margin: '0px', once: false, root: popoverRef.current });

  const { filter, filteredKeys, onFilterChange, sort } = useMenuSearch<SelectOption>(options, {
    hiddenKeys,
    matchOptions
  });

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
    onOpenChange?.(isOpen);
    if (!isOpen) {
      onFilterChange('');
    }
  };

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    onFilterChange(e.target.value);
    onFilterChangeProp?.(e.target.value);
  };

  const selectedKeys = useMemo<MenuKey[]>(
    () => (Array.isArray(value) ? value : [value]).filter(Boolean) as MenuKey[],
    [value]
  );
  const disabledKeys = useMemo<MenuKey[]>(
    () => initialDisabledKeys || options.filter((it) => it.disabled).map((it) => it.value),
    [initialDisabledKeys, options]
  );
  const selectedProperties = useMemo(
    () => items?.filter((it) => it.id && selectedKeys.includes(it.id)),
    [items, selectedKeys]
  );

  useLayoutEffect(() => {
    if (loadMore && endRef.current && popoverRef.current && endOfList && open) {
      loadMore();
    }
  }, [endOfList, open, endRef.current, popoverRef.current, loadMore]);

  return (
    <Dropdown
      withChevron
      type={'menu'}
      size={'xs'}
      color={'tertiary'}
      className={'!max-h-[55vh] overflow-x-hidden'}
      label={
        typeof label === 'function' ? (
          label(selectedProperties, { open })
        ) : (
          <DropdownMenuLabel
            open={open}
            prefix={label}
            selectedProperties={selectedProperties}
            multiple={multiple}
            placeholder={placeholder}
          />
        )
      }
      ref={ref}
      popoverRef={popoverRef}
      {...rest}
      onOpenChange={handleOpenChange}
      open={open}
    >
      {withSearch ? (
        <DropdownMenuSearchField
          autoFocus={open}
          value={filter}
          placeholder={searchPlaceholder}
          onChange={handleFilterChange}
        />
      ) : null}

      <DropdownMenu
        className={classNames('max-h-[auto]', dropdownMenuClassName)}
        emptyList={emptyList}
        selectionMode={multiple ? 'multiple' : 'single'}
        selectedKeys={selectedKeys}
        disabledKeys={disabledKeys}
        hiddenKeys={filteredKeys}
        withCheckMark={false}
        items={items}
        MenuSectionHeaderComponent={MenuSectionHeaderComponent}
        onAction={onChange}
        onSelectionChange={onSelectionChange}
        sortFn={sort}
      />
      <div ref={endRef} style={{ height: '10px' }} />
      {optionsFooter}
    </Dropdown>
  );
});
