import React from 'react';
import { Icon, IconProps } from '../components/Icon';

export const SuperAdsIconSvg = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 100 105.79'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      {...props}
    >
      <g
        style={{
          offsetPath:
            "path('M76.017174,21.000021C89.726761,34.200722,91.492086,60.056222,84.42,72.999964C69.858965,95.594372,35.614591,94.887349,16.35,76.57C8.584725,62.226573,6.209414,34.400243,22.35,20.28C37.405988,9.682552,63.629306,12.331072,76.017174,21.000021')",
          offsetRotate: '0deg'
        }}
      >
        <g>
          <path
            d='M68.5977,21.0039c-11.6042,0-20.9962,9.392-20.9962,20.9962c0-11.6042-9.3997-20.9962-21.0038-20.9962c11.6041,0,21.0038-9.3997,21.0038-21.003843c0,11.604143,9.392,21.003843,20.9962,21.003843Z'
            transform='translate(-47.5977,-21.000078)'
            className='fill-accent-1'
          />
        </g>
      </g>
      <path
        d='M42,52.0039c-11.6041,0-20.9961,9.392-20.9961,20.9961c0-11.6041-9.3998-20.9961-21.0039-20.9961c11.6041,0,21.0039-9.3998,21.0039-21.0039c0,11.6041,9.392,21.0039,20.9961,21.0039Z'
        transform='translate(28.419474-.000057)'
        className='fill-primary-5'
      />
      <path
        d='M21,73c11.598,0,21-9.402,21-21s-9.402-21-21-21-21,9.402-21,21s9.40202,21,21,21Z'
        transform='translate(28.419474-.000057)'
        className='fill-primary-5'
      />
      <g
        style={{
          offsetPath:
            "path('M76.419474,19.999943C84.847379,31.741989,95.50354,52.11125,84.42,73.57C70.008916,88.76309,38.885231,96.929753,16.35,76.57C7.099977,62.047846,9.00331,33.893431,22.35,20.28C35.474824,9.91008,62.509824,10.529706,76.42,20')",
          offsetRotate: '0deg'
        }}
      >
        <path
          d='M48,26c3.3137,0,6-2.6863,6-6s-2.6863-6-6-6-6,2.6863-6,6s2.6863,6,6,6Z'
          transform='translate(-48,-20)'
          className='fill-accent-1'
        />
      </g>
    </svg>
  );
};
export const SuperAdsIcon = (props: IconProps['iconProps']) => (
  <Icon icon={SuperAdsIconSvg} iconProps={props} />
);
