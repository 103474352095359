import { useCallback, useEffect, useState } from 'react';

export type useCopyToClipboardProps = {
  text?: string;
  hoverTooltipText?: string;
  copiedTooltipText?: string;
  hovered?: boolean;
  success?: boolean;
  onCopy?: () => void;
};

const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

export const useCopyToClipboard = (props: useCopyToClipboardProps) => {
  const {
    text,
    hoverTooltipText = 'Copy',
    copiedTooltipText = 'Copied!',
    hovered,
    success,
    onCopy
  } = props;

  const [isCopied, setIsCopied] = useState(success);

  const hideMessage = useCallback(() => {
    setIsCopied(false);
  }, []);

  const copy = useCallback(
    async (textToBeCopied?: string) => {
      const textToCopy = textToBeCopied || text;

      if (textToCopy) {
        await copyToClipboard(textToCopy);
        setIsCopied(true);
        onCopy?.();
      }
    },
    [text, onCopy]
  );

  useEffect(() => {
    const timeout = isCopied && setTimeout(hideMessage, 2000);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isCopied, hideMessage]);

  const hoverTip = {
    title: hoverTooltipText,
    open: hovered
  };

  const copiedTip = {
    title: copiedTooltipText,
    forceOpen: true,
    open: true
  };

  return {
    copy,
    isCopied,
    tip: isCopied ? copiedTip : hoverTip
  };
};
