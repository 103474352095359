export const getElemOffset = (
  elem: HTMLElement | null,
  opts: { orientation: 'horizontal' | 'vertical'; direction: 'right' | 'left' | 'top' | 'bottom' }
) => {
  const rect = elem?.getBoundingClientRect();

  return (
    rect?.[
      opts.orientation === 'horizontal'
        ? opts.direction === 'left'
          ? 'right'
          : 'left'
        : opts.direction === 'top'
          ? 'bottom'
          : 'top'
    ] || 0
  );
};
