export const getTriggerOffset = (
  trigger: HTMLElement | null,
  opts: { orientation: 'horizontal' | 'vertical'; direction: 'right' | 'left' | 'top' | 'bottom' }
) => {
  const rect = trigger?.getBoundingClientRect();

  if (!rect) {
    return { start: 0, size: 0, offset: 0 };
  }

  const start = rect[opts.orientation === 'horizontal' ? 'left' : 'top'];
  const size = rect[opts.orientation === 'horizontal' ? 'width' : 'height'];
  const offset = start + size / 2;

  return { start, size, offset };
};
