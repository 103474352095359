'use client';
import React from 'react';

import styles from './styles.module.css';
import { SuperAdsIcon } from '../../icons/SuperAdsIcon';
import { UiSize } from '../../types';
import { classNames } from '../../utils/classNames';
import { normalizeSize } from '../../utils/size';

export type LoaderProps = {
  size?: UiSize;
  className?: string;
};

export const Loader: React.FC<LoaderProps> = (props) => {
  const { className } = props;
  const size = normalizeSize(props.size || 'small');

  return (
    <div
      className={classNames(
        'flex shrink-0 grow-0 mx-auto text-center items-center justify-center',
        className
      )}
    >
      <SuperAdsIcon
        className={classNames('inline text-white', styles.loader, {
          'w-6 h-6': size === 'xs',
          'w-8 h-8': size === 'sm',
          'w-10 h-10': size === 'md',
          'w-12 h-12': size === 'lg'
        })}
      />
    </div>
  );
};
