'use client';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { createCalendar, getDayOfWeek, getWeeksInMonth, isSameDay } from '@internationalized/date';
import React, { ReactNode, useRef } from 'react';
import {
  mergeProps,
  useButton,
  useCalendarCell,
  useCalendarGrid,
  useFocusRing,
  useLocale,
  useRangeCalendar
} from 'react-aria';
import { useRangeCalendarState } from 'react-stately';

export type CalendarButtonProps = {
  isDisabled?: boolean;
  children: ReactNode;
};

function CalendarButton(props: CalendarButtonProps) {
  const ref = useRef();
  const { buttonProps } = useButton(props, ref);
  const { focusProps, isFocusVisible } = useFocusRing();
  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      ref={ref}
      className={`p-1.5 rounded-full ${props.isDisabled ? 'text-gray-400' : ''} ${
        !props.isDisabled ? 'hover:bg-violet-100 active:bg-violet-200' : ''
      } outline-none ${isFocusVisible ? 'ring-2 ring-offset-2 ring-purple-600' : ''}`}
    >
      {props.children}
    </button>
  );
}

export function RangeCalendar(props) {
  const { locale } = useLocale();
  const state = useRangeCalendarState({
    ...props,
    locale,
    createCalendar
  });

  const ref = useRef();
  const { calendarProps, prevButtonProps, nextButtonProps, title } = useRangeCalendar(
    props,
    state,
    ref
  );

  return (
    <div {...calendarProps} ref={ref} className='inline-block'>
      <div className='flex items-center'>
        <span className='flex-1 text-gray-750 text-xs font-semibold ml-3 uppercase tracking-wider'>
          {title}
        </span>
        <CalendarButton {...prevButtonProps}>
          <ChevronLeftIcon className='h-6 w-6 text-gray-500' />
        </CalendarButton>
        <CalendarButton {...nextButtonProps}>
          <ChevronRightIcon className='h-6 w-6 text-gray-500' />
        </CalendarButton>
      </div>
      <CalendarGrid state={state} />
    </div>
  );
}

function CalendarGrid({ state, ...props }) {
  const { locale } = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <table {...gridProps} cellPadding='0' className='flex-1'>
      <thead {...headerProps} className='text-gray-400 text-xs font-semibold'>
        <tr>
          {weekDays.map((day, index) => (
            <th className='text-center h-9 w-9 font-normal' key={index}>
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? <CalendarCell key={i} state={state} date={date} /> : <td key={i} />
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function CalendarCell({ state, date }) {
  const ref = useRef();
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    formattedDate,
    isInvalid
  } = useCalendarCell({ date }, state, ref);

  // The start and end date of the selected range will have
  // an emphasized appearance.
  const isSelectionStart = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.start)
    : isSelected;
  const isSelectionEnd = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.end)
    : isSelected;

  // We add rounded corners on the left for the first day of the month,
  // the first day of each week, and the start date of the selection.
  // We add rounded corners on the right for the last day of the month,
  // the last day of each week, and the end date of the selection.
  const { locale } = useLocale();
  const dayOfWeek = getDayOfWeek(date, locale);
  const isRoundedLeft = isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
  const isRoundedRight =
    isSelected &&
    (isSelectionEnd || dayOfWeek === 6 || date.day === date.calendar.getDaysInMonth(date));

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <td {...cellProps} className={`py-0.5 relative ${isFocusVisible ? 'z-10' : 'z-0'}`}>
      <div
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        hidden={isOutsideVisibleRange}
        className={`w-9 h-9 text-sm outline-none group ${isRoundedLeft ? 'rounded-l-full' : ''} ${
          isRoundedRight ? 'rounded-r-full' : ''
        } ${isSelected ? (isInvalid ? 'bg-red-300' : 'bg-gray-200') : ''} ${
          isDisabled ? 'disabled' : ''
        }`}
      >
        <div
          className={`w-full h-full rounded-full flex items-center justify-center ${
            isDisabled && !isInvalid ? 'text-gray-400' : ''
          } ${
            // Focus ring, visible while the cell has keyboard focus.
            isFocusVisible ? 'ring-2 group-focus:z-2 ring-primary-4 ring-offset-2' : ''
          } ${
            // Darker selection background for the start and end.
            isSelectionStart || isSelectionEnd
              ? isInvalid
                ? 'bg-red-600 text-white hover:bg-red-700'
                : 'bg-black/80 text-white hover:bg-black/90'
              : ''
          } ${
            // Hover state for cells in the middle of the range.
            isSelected && !isDisabled && !(isSelectionStart || isSelectionEnd)
              ? isInvalid
                ? 'hover:bg-red-400'
                : 'hover:bg-black/5'
              : ''
          } ${
            // Hover state for non-selected cells.
            !isSelected && !isDisabled ? 'hover:bg-black/5' : ''
          } cursor-default`}
        >
          {formattedDate}
        </div>
      </div>
    </td>
  );
}
