'use client';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useRef } from 'react';

export const useStreamQueryFn = (props: {
  url: string;
  queryKey: QueryKey;
  method?: string;
  body?: any;
}) => {
  const { queryKey, url, method, body } = props;

  const queryClient = useQueryClient();
  const abortControllerRef = useRef<AbortController | null>();

  const { mutate, error, isPending } = useMutation({
    mutationKey: queryKey,
    mutationFn: async () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const controller = new AbortController();
      const signal = controller.signal;
      abortControllerRef.current = controller;

      for await (const token of getCompletion(url, {
        signal,
        method,
        body
      })) {
        queryClient.setQueryData<string>(queryKey, (prev) => (prev ? prev + token : token));
      }
      abortControllerRef.current = null;
    }
  });

  const queryFn = useCallback(async () => {
    await mutate();
    return '';
  }, [mutate]);

  return { queryFn, error, isLoading: isPending };
};

async function* getCompletion(
  url: string,
  opts: {
    method?: string;
    body?: any;
    signal?: AbortSignal;
  }
) {
  const { method = 'GET', body, signal } = opts;
  const res = await fetch(url, {
    method,
    body,
    signal
  });

  const reader = res.body?.getReader();
  if (!reader) throw new Error('No reader');
  const decoder = new TextDecoder();

  let i = 0;
  while (i < 1000) {
    i++;
    const { done, value } = await reader.read();
    if (done) return;
    const token = decoder.decode(value);
    yield token;

    if (signal?.aborted) {
      await reader.cancel();
      return;
    }
  }
}
