import { ChevronRightIcon } from '@heroicons/react/20/solid';
import Link, { LinkProps } from 'next/link';
import React, { ReactNode } from 'react';
import {
  Breadcrumb as AriaBreadcrumb,
  BreadcrumbProps as AriaBreadcrumbProps,
  Breadcrumbs as AriaBreadcrumbs,
  BreadcrumbsProps
} from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { classNames } from '../../utils';

export type BreadcrumbProps = AriaBreadcrumbProps &
  (
    | ({ href?: LinkProps['href'] } & Omit<LinkProps, 'className' | 'id' | 'href' | 'ref'> & {
          target?: string;
        })
    | ({ href?: never } & Omit<React.ComponentProps<'span'>, 'className' | 'id'>)
  ) & { chevronRight?: boolean };

export function Breadcrumbs<T extends object>(props: BreadcrumbsProps<T>) {
  return (
    <AriaBreadcrumbs {...props} className={twMerge('flex items-center gap-1', props.className)} />
  );
}

export function Breadcrumb(props: BreadcrumbProps) {
  const { className, id, style, chevronRight, ...linkProps } = props;

  return (
    <AriaBreadcrumb
      {...props}
      id={id}
      style={style}
      className={classNames(className, 'flex items-center gap-1 leading-4')}
    >
      {props.href ? (
        <Link
          {...linkProps}
          href={props.href as LinkProps['href']}
          className={
            'text-xs text-gray-500 whitespace-nowrap font-medium hover:text-gray-700 transition-colors max-w-96 truncate'
          }
        >
          {props.children as ReactNode}
        </Link>
      ) : typeof props.children === 'string' ? (
        <span
          className={
            'text-xs text-gray-500 whitespace-nowrap font-medium hover:text-gray-700 transition-colors max-w-96 truncate'
          }
        >
          {props.children}
        </span>
      ) : (
        (props.children as ReactNode)
      )}
      {(props.href || chevronRight) && (
        <ChevronRightIcon className='w-4 h-4 min-w-4 text-gray-500' />
      )}
    </AriaBreadcrumb>
  );
}
