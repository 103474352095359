'use client';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import type { PopoverProps } from 'react-aria-components';
import { DialogTrigger, MenuTrigger, Popover } from 'react-aria-components';
import { mergeRefs } from 'react-merge-refs';
import { DropdownButton, DropdownButtonProps } from './DropdownButton';
import { classNames } from '../../utils/classNames';

export type DropdownProps = Pick<
  DropdownButtonProps,
  | 'size'
  | 'color'
  | 'weight'
  | 'wFull'
  | 'icon'
  | 'iconProps'
  | 'ToggleIcon'
  | 'isLoading'
  | 'isStatic'
  | 'tip'
> &
  Pick<PopoverProps, 'triggerRef' | 'offset' | 'crossOffset' | 'placement'> & {
    label?: React.ReactNode;
    type?: 'dialog' | 'menu';
    className?: string;
    open?: boolean;
    disabled?: boolean;
    controlled?: boolean;
    withChevron?: boolean;
    width?: string;
    height?: string;
    useButtonWidth?: boolean;
    Button?: React.ReactNode | React.FC<DropdownButtonProps>;
    popoverRef?: React.RefObject<HTMLElement>;
    buttonProps?: Partial<DropdownButtonProps>;
    buttonClassName?: DropdownButtonProps['className'];
    children: React.ReactNode;
    onOpenChange?: (isOpen: boolean) => void;
    'data-userflow-id'?: string;
  };

export const Dropdown = forwardRef((props: DropdownProps, ref) => {
  const {
    className,
    type = 'dialog',
    size,
    color,
    weight,
    icon,
    iconProps,
    wFull,
    open = false,
    tip,
    disabled,
    isLoading,
    isStatic,
    placement = 'bottom start',
    offset,
    crossOffset,
    label,
    withChevron,
    width,
    height,
    useButtonWidth,
    Button = DropdownButton,
    ToggleIcon,
    buttonProps,
    buttonClassName,
    triggerRef,
    popoverRef,
    children,
    onOpenChange,
    controlled
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);

  const TriggerComponent = type === 'menu' ? MenuTrigger : DialogTrigger;

  const [isOpen, setIsOpen] = useState(open || false);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!controlled) {
        setIsOpen(isOpen);
      }
      onOpenChange?.(isOpen);
    },
    [controlled, onOpenChange]
  );

  useEffect(() => {
    window.closeDropdown = () => handleOpenChange(false);

    return () => {
      window.closeDropdown = undefined;
    };
  }, [handleOpenChange]);

  useEffect(() => {
    if (isOpen !== open) {
      setIsOpen(open);
    }
  }, [open]);

  return (
    <TriggerComponent isOpen={isOpen && !disabled} onOpenChange={handleOpenChange}>
      {/* @ts-expect-error  JSX element type 'Button' does not have any construct or call signatures. */}
      <Button
        ref={mergeRefs([buttonRef, ref])}
        size={size}
        color={color}
        weight={weight}
        wFull={wFull}
        isOpen={isOpen}
        isStatic={isStatic}
        isLoading={isLoading}
        isDisabled={disabled}
        withChevron={withChevron}
        disableExtraIconPadding
        tip={isOpen ? undefined : tip}
        icon={icon}
        iconProps={iconProps}
        ToggleIcon={ToggleIcon}
        {...buttonProps}
        className={classNames(buttonProps?.className, buttonClassName)}
      >
        {label}
      </Button>
      <Popover
        ref={popoverRef}
        triggerRef={triggerRef}
        placement={placement}
        offset={offset}
        crossOffset={crossOffset}
        style={{
          width,
          height,
          minWidth: useButtonWidth ? buttonRef.current?.clientWidth : 'auto'
        }}
        className={classNames(
          className,
          'overflow-y-auto bg-white rounded shadow-lg ring-1 ring-black ring-opacity-5 max-w-[calc(100vw-2rem)]',
          'entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 fill-mode-forwards'
        )}
        data-userflow-id={props['data-userflow-id']}
      >
        {children}
      </Popover>
    </TriggerComponent>
  );
});
