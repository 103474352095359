import React from 'react';
import { ForwardRef, isValidElementType } from 'react-is';
import { UiSize } from '../../types';
import { classNames } from '../../utils/classNames';

export interface IconProps {
  icon?: React.ReactNode | React.FC<any> | typeof ForwardRef;
  iconProps?: { className?: string; size?: UiSize; color?: 'neutral' };
  size?: UiSize;
  color?: 'neutral';
}

export const Icon: React.FC<IconProps> = (props) => {
  const { icon, iconProps, size = iconProps?.size || 'md', color = iconProps?.color } = props;

  const className = classNames(
    'shrink-0',
    {
      'w-2 h-2': size === 'xs',
      'w-3 h-3': size === 'sm',
      'w-4 h-4': size === 'md',
      'w-6 h-6': size === 'lg',
      'w-8 h-8': size === 'xl'
    },
    {
      'text-gray-500': color === 'neutral'
    },
    iconProps?.className
  );

  if (typeof icon === 'string') {
    return <img className={className} aria-hidden='true' src={icon} />;
  }

  if (isValidElementType(icon)) {
    const Component = icon;

    return <Component {...iconProps} className={className} />;
  }

  return icon as React.ReactNode;
};
