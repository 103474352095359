'use client';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState, useTransition } from 'react';

export const useImmediateItemState = <T extends any | undefined>(
  initialState: T | undefined,
  onChange: (state: T) => void,
  timeout = 500
): [T | undefined, (state: T) => void] => {
  const [_, startTransition] = useTransition();

  const [selected, setSelected] = useState<T | undefined>(initialState);

  const debouncedOnChange = useMemo(
    () => onChange && debounce(onChange, timeout),
    [onChange, timeout]
  );

  const handleChange = useCallback(
    (state: T) => {
      setSelected(state);
      debouncedOnChange && startTransition(() => debouncedOnChange(state));
    },
    [debouncedOnChange]
  );

  useEffect(() => setSelected(initialState), [initialState]);

  return [selected, handleChange];
};
