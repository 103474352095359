'use client';
import { useCallback, useEffect, useRef, useState } from 'react';

declare let window: Window;

export const useLocalStorage = <T,>(
  key: string,
  initialValue: T,
  opts: { generation?: number } = {}
) => {
  const generation = opts?.generation;

  const [storedValue, setStoredValue] = useState<T | undefined>(() =>
    key ? getInitialState(initialValue, key, generation) : undefined
  );

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      if (!key) {
        return;
      }

      try {
        return setStoredValue((storedValue) => {
          const nextValue = value instanceof Function ? value(storedValue) : value;

          if (window && window.localStorage) {
            localStorage.setItem(
              key,
              JSON.stringify({
                generation,
                value: nextValue
              })
            );
          }

          return nextValue;
        });
      } catch (error) {
        console.error(error);
      }
    },
    [key, generation]
  );

  const sync = useCallback(() => {
    if (!key) {
      return;
    }
    const initialState = getInitialState(initialValue, key, generation);

    setStoredValue(initialState);
  }, [generation, initialValue, key]);

  const prevKey = useRef(key);
  const prevGeneration = useRef(generation);

  useEffect(() => {
    if (!key) {
      return;
    }

    if (prevKey.current === key && prevGeneration.current === generation) {
      return;
    }

    try {
      const initialState = getInitialState(initialValue, key, generation);

      setValue(initialState);
      prevKey.current = key;
      prevGeneration.current = generation;
    } catch (e) {
      console.error(e);
    }
  }, [key, generation]);

  return [storedValue, setValue, sync] as const;
};

const getInitialState = <T,>(initialValue: T, key: string, generation?: number): T => {
  try {
    if (typeof window === 'undefined') {
      // console.warn('Can`t use local storage with SSR. Initial value will be used.');

      return initialValue;
    }

    let item = key && window && window.localStorage.getItem(key);

    if (item) {
      item = JSON.parse(item);

      if (typeof generation === 'undefined' || item?.generation === generation) {
        return item.value;
      }
    }

    return initialValue;
  } catch (error) {
    return initialValue;
  }
};

export default useLocalStorage;
