'use client';
import { useComposedRefs } from '@radix-ui/react-compose-refs';
import cookie from 'js-cookie';
import debounce from 'lodash/debounce';
import React, {
  PointerEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useLocalStorage } from '../useLocalStorage';
import { getElemOffset } from './utils/getElemOffset';
import { getPointerShift } from './utils/getPointerShift';

export type UseResizableElementProps = {
  enabled?: boolean;
  offset?: number;
  minValue?: number;
  defaultValue?: number;
  maxValue?: number;
  orientation?: 'horizontal' | 'vertical';
  direction?: 'top' | 'bottom' | 'left' | 'right';
  isDynamicPosition?: boolean;
  storageKey?: string;
  cookieKey?: string;
  generation?: number;
  parseStorage?: (value: number) => number;
  formatStorage?: (value: number) => number;
  onStart?(): void;
  onEnd?(): void;
  onResize?(value: number): void;
  onChange?(value: number): void;
};

export const useResizableElement = (props: UseResizableElementProps) => {
  const {
    enabled = true,
    offset = 0,
    minValue = 0,
    maxValue = Infinity,
    defaultValue,
    direction = 'left',
    orientation = 'horizontal',
    storageKey,
    cookieKey,
    generation,
    elemRef: initialElemRef,
    handlerRef: initialHandlerRef,
    parseStorage = Number,
    formatStorage,
    onStart,
    onEnd,
    onResize,
    onChange,

    isDynamicPosition
  } = props;

  const [storageValue, setStorageValue] = useLocalStorage<number | null>(storageKey || '', null, {
    generation
  });
  const cookieValue = cookieKey ? cookie.get(cookieKey) : undefined;

  const rowStoredValue = cookieValue || storageValue;
  const storedValue =
    typeof rowStoredValue !== 'undefined' && rowStoredValue !== null
      ? parseStorage(rowStoredValue)
      : undefined;

  const saveValue = useMemo(
    () =>
      debounce((value: number | null) => {
        const finalValue = formatStorage ? formatStorage(value) : value;

        if (storageKey) {
          setStorageValue(finalValue);
        }
        if (cookieKey) {
          cookie.set(cookieKey, finalValue);
        }
      }, 1000),
    [storageKey, cookieKey, formatStorage]
  );

  const elemRef = useRef<HTMLElement | null>(null);
  const ref = useComposedRefs(elemRef, initialElemRef);
  const resizerRef = useRef<HTMLDivElement>(null);
  const handlerRef = useComposedRefs(resizerRef, initialHandlerRef);

  const [value, setValue] = useState<number | undefined>(() =>
    enabled
      ? Math.min(Math.max(storedValue ?? defaultValue ?? 0, minValue), maxValue)
      : defaultValue || undefined
  );

  const [isResizing, setIsResizing] = useState(false);

  const [staticValue, setStaticValue] = useState<number | undefined>(value);

  const elemOffsetRef = useRef(0);
  const pointerShiftRef = useRef(0);

  const handlePointerDown = useCallback<PointerEventHandler<HTMLDivElement>>(
    (e) => {
      e?.stopPropagation();
      e?.preventDefault();

      if (!isDynamicPosition) {
        elemOffsetRef.current = getElemOffset(elemRef?.current, { orientation, direction });
      }

      const resizer = resizerRef.current || e?.currentTarget;

      if (resizer && e) {
        pointerShiftRef.current = getPointerShift(
          resizer,
          orientation === 'horizontal' ? e.clientX : e.clientY,
          { orientation, direction }
        );
      }

      setIsResizing(true);
      onStart?.();
    },
    [orientation, direction, onStart, isDynamicPosition]
  );

  const handlePointerMove = useCallback(
    (e: PointerEvent) => {
      setValue((previousValue) => {
        const elemOffset = isDynamicPosition
          ? getElemOffset(elemRef?.current, { orientation, direction })
          : elemOffsetRef.current;

        const position = orientation === 'horizontal' ? e.clientX : e.clientY;

        const newValue =
          offset +
          (direction === 'left' || direction === 'top'
            ? elemOffset - position - pointerShiftRef.current
            : position - elemOffset + pointerShiftRef.current);

        if (newValue >= minValue && newValue <= maxValue) {
          return newValue;
        } else {
          return previousValue;
        }
      });
    },
    [orientation, direction, offset, minValue, maxValue, isDynamicPosition]
  );

  const handlePointerUp = useCallback(() => {
    setIsResizing(false);
    onEnd?.();
  }, [onEnd]);

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('pointermove', handlePointerMove);
      window.addEventListener('pointerup', handlePointerUp);
    }

    return () => {
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
    };
  }, [isResizing, handlePointerMove, handlePointerUp]);

  useEffect(() => {
    if (!enabled || typeof staticValue === 'undefined') {
      return;
    }

    saveValue(staticValue);
  }, [staticValue, saveValue, enabled]);

  useEffect(() => {
    if (!enabled || isResizing) {
      return;
    }

    setValue((previousValue) =>
      Math.max(Math.min(previousValue ?? defaultValue, maxValue), minValue)
    );
  }, [minValue, maxValue, defaultValue, enabled]);

  const prevValue = useRef(value);
  useEffect(() => {
    if (!enabled || typeof value === 'undefined') {
      return;
    }

    if (!isResizing) {
      setStaticValue(value);
      onChange?.(value);
    } else if (prevValue.current !== value) {
      onResize?.(value);
      prevValue.current = value;
    }
  }, [enabled, isResizing, value]);

  return {
    ref,
    handlerRef,
    value,
    staticValue,
    isResizing,
    handleHandlerPointerDown: handlePointerDown,

    width: value
  };
};
