import React from 'react';
import { classNames } from '../../utils/classNames';
import { ControlTooltipProps } from '../Button/types';
import { Tooltip, useToolTip } from '../Tooltip';

export interface ProgressbarProps {
  percentage: number;
  className?: string;
  disablePulse?: boolean;
  tip?: React.ReactNode | Partial<ControlTooltipProps>;
  tipProps?: Partial<ControlTooltipProps>;
}

export const Progressbar: React.FC<ProgressbarProps> = (props) => {
  const percentage = props.percentage;
  const tooltip = useToolTip(props.tip, props.tipProps);

  return (
    <>
      <div
        data-tooltip-id={tooltip?.id}
        className={classNames('bg-neutral-2 rounded-full h-3 overflow-hidden', props.className)}
      >
        {percentage >= 0 ? (
          <div
            className={classNames(
              'h-full bg-primary-5 text-xs font-medium text-center p-0.5 leading-none',
              percentage !== 100 && !props.disablePulse ? 'animate-pulse' : ''
            )}
            style={{ width: `${percentage}%`, minWidth: '2px' }}
          />
        ) : null}
      </div>

      {tooltip ? <Tooltip {...tooltip}>{tooltip.content}</Tooltip> : null}
    </>
  );
};
