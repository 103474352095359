'use client';

import { useCallback, useEffect, useRef } from 'react';

export const useTextAreaAutoSizer = ({ value }) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const prevValueRef = useRef(value);

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return;
    }
    ref.current.style.height = 'auto';
    ref.current.style.height = ref.current.scrollHeight + 'px';
  }, [ref]);

  useEffect(() => {
    if (!ref) {
      return;
    }

    handleResize();

    if (ref.current && prevValueRef.current !== value) {
      ref.current.scrollIntoViewIfNeeded();
    }

    prevValueRef.current = value;
  }, [value, ref]);

  return ref;
};
