'use client';
import React from 'react';
import { ModalBody } from './ModalBody';
import { ModalCloseButton } from './ModalCloseButton';
import { ModalContainer } from './ModalContainer';
import { ModalDialog } from './ModalDialog';
import { ModalHeader } from './ModalHeader';
import { ModalHeading, ModalSubHeading } from './ModalHeading';
import { ModalOverlay } from './ModalOverlay';
import { ModalProps } from './types';
import { classNames } from '../../utils';

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    show,
    size = 'lg',
    animate = 'fade',
    position = 'center',
    wFull = true,
    hFull,
    isDismissable = true,
    overflowHidden = false,
    children,
    onClose,
    dialogClassName,
    ...rest
  } = props;

  return (
    <ModalOverlay
      isOpen={show}
      isDismissable={isDismissable}
      className={'p-4'}
      position={position}
      onOpenChange={(isOpen) => !isOpen && onClose()}
    >
      <ModalContainer size={size} wFull={wFull} hFull={hFull} animate={animate} position={position}>
        <ModalDialog
          position={position}
          overflowHidden={overflowHidden}
          className={dialogClassName}
        >
          {({ close }) => (
            <ModelContent {...rest} overflowHidden={overflowHidden} close={close} size={size}>
              {children}
            </ModelContent>
          )}
        </ModalDialog>
      </ModalContainer>
    </ModalOverlay>
  );
};

const ModelContent = (
  props: Omit<
    ModalProps,
    'show' | 'animate' | 'position' | 'wFull' | 'hFull' | 'isDismissable' | 'onClose'
  > & {
    close: () => void;
  }
) => {
  const {
    absoluteHeader,
    type,
    title,
    subtitle,
    withClose = true,
    overflowHidden,
    noPadding,
    children,
    close
  } = props;

  if (type === 'announcement') {
    return <AnnouncementModelContent {...props} />;
  }

  return (
    <>
      {title || subtitle || withClose ? (
        <ModalHeader absolute={absoluteHeader}>
          <div className={classNames('flex flex-col gap-y-1')}>
            {title ? <ModalHeading>{title}</ModalHeading> : null}
            {subtitle ? <ModalSubHeading>{subtitle}</ModalSubHeading> : null}
          </div>
          {withClose ? <ModalCloseButton onPress={close} /> : null}
        </ModalHeader>
      ) : null}

      <ModalBody overflowHidden={overflowHidden} noPadding={noPadding}>
        {typeof children === 'function' ? children({ close }) : children}
      </ModalBody>
    </>
  );
};

const AnnouncementModelContent = (
  props: Omit<
    ModalProps,
    'show' | 'animate' | 'position' | 'wFull' | 'hFull' | 'isDismissable' | 'onClose'
  > & {
    close: () => void;
  }
) => {
  const {
    absoluteHeader,
    title,
    subtitle,
    withClose,
    overflowHidden,
    noPadding,
    children,
    beforeHeader,
    close
  } = props;

  return (
    <>
      {beforeHeader}
      {title || subtitle || withClose ? (
        <ModalHeader absolute={absoluteHeader}>
          <div className={classNames('flex flex-col gap-y-4', 'w-full text-center')}>
            {title ? <ModalHeading className={'text-3xl'}>{title}</ModalHeading> : null}
            {subtitle ? <ModalSubHeading>{subtitle}</ModalSubHeading> : null}
          </div>
          {withClose ? <ModalCloseButton onPress={close} /> : null}
        </ModalHeader>
      ) : null}

      <ModalBody overflowHidden={overflowHidden} noPadding={noPadding}>
        {typeof children === 'function' ? children({ close }) : children}
      </ModalBody>
    </>
  );
};
