import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { classNames } from '../../utils/classNames';
import { Button, ButtonProps } from '../Button';

export type DropdownButtonProps = ButtonProps & {
  className?: string;
  isDisabled?: boolean;
  isOpen?: boolean;
  children: React.ReactNode;
  truncate?: boolean;
  withChevron?: boolean;
  toggleIconProps?: ButtonProps['iconProps'];
  ToggleIcon?: React.FC<any>;
  onClick?: () => void;
  onOpen?: (isOpen: boolean) => void;
  'data-tooltip-id'?: string;
  'data-tooltip-content'?: string;
  'data-userflow-id'?: string;
  'data-test-id'?: string;
};

export const DropdownButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps>(
  (props, ref) => {
    const {
      size = 'md',
      color,
      withChevron,
      isOpen,
      isStatic,
      isDisabled,
      children,
      ToggleIcon,
      truncate = true,
      toggleIconProps,
      ...rest
    } = props;

    const ChevronIconComponent =
      ToggleIcon ||
      (withChevron
        ? (props) => (
            <ChevronDownIcon
              className={classNames(props.className, isOpen ? 'rotate-180' : null)}
            />
          )
        : null);

    const withToggleIcon = !isDisabled && !isStatic && ChevronIconComponent;

    return (
      <Button
        ref={ref}
        size={size}
        color={color}
        isActive={Boolean(isOpen)}
        isStatic={Boolean(isStatic)}
        isDisabled={Boolean(isDisabled)}
        {...rest}
      >
        {children || withToggleIcon ? (
          <div
            className={classNames(
              'flex flex-1 items-center justify-between max-w-full',
              truncate ? 'truncate' : ''
            )}
          >
            {children}
            {withToggleIcon && (
              <ChevronIconComponent
                className={classNames(
                  'shrink-0 ml-1 -mr-1 transition',
                  'h-4 w-4',
                  { [isOpen ? 'text-white' : 'text-gray-500']: color === 'tertiary' },
                  { [isOpen ? 'text-black' : 'text-gray-500']: color !== 'tertiary' },
                  toggleIconProps?.className
                )}
                aria-hidden='true'
              />
            )}
          </div>
        ) : null}
      </Button>
    );
  }
);
