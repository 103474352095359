import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Button, ButtonProps } from './Button';
import { classNames } from '../../utils';

export const DragButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <Button
    ref={ref}
    size={'sm'}
    noBorder
    {...props}
    className={classNames('cursor-grab flex items-center text-gray-500 gap-0 p-0', props.className)}
  >
    <EllipsisVerticalIcon className={'w-4 h-4'} />
    <EllipsisVerticalIcon className={'w-4 h-4'} style={{ marginLeft: '-12px' }} />
  </Button>
));
