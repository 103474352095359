'use client';
import React from 'react';
import { SpinnerIcon } from './SpinnerIcon';
import { LegacyUiSize, UiSize } from '../../types';
import { classNames } from '../../utils/classNames';
import { normalizeSize } from '../../utils/size';

export type SpinnerProps = SVGLoaderProps & {
  className?: string;
};

export const Spinner: React.FC<SpinnerProps> = (props) => {
  const { size } = props;

  return (
    <div
      className={classNames(
        'flex shrink-0 grow-0 mx-auto text-center items-center justify-center',
        props.className
      )}
    >
      <SVGLoader size={size} />
    </div>
  );
};

export default Spinner;

export type SVGLoaderProps = {
  size?: LegacyUiSize | UiSize;
};
export const SVGLoader: React.FC<SVGLoaderProps> = (props) => {
  const { size = 'medium' } = props;

  return <SpinnerIcon className={'inline text-white animate-spin'} size={normalizeSize(size)} />;
};
