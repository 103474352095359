import React, { useMemo, useState } from 'react';
import { IMenuItem, MenuKey } from '../types';
import { matchMenuItem } from '../utils';

export type UseMenuSearch<O extends IMenuItem | '-'> = {
  filter: string | undefined;
  filteredKeys: MenuKey[];
  onFilterChange: (filter: string | undefined) => void;
  sort: (a: O, b: O) => number;
};

const defaultMatchOptions = (options: IMenuItem[], filter: string): MenuKey[] =>
  options
    .filter((option) => !matchMenuItem(option.searchKey || option.label, filter))
    .map((option) => (option.value || option.id) as MenuKey);

const sortByMatchPriority =
  (filter?: string) =>
  (a: IMenuItem, b: IMenuItem): number => {
    const lowerFilter = filter?.toLowerCase() || '';
    const aText = a.searchKey?.toLowerCase() || (a.label as string)?.toLowerCase() || '';
    const bText = b.searchKey?.toLowerCase() || (b.label as string)?.toLowerCase() || '';

    // Scoring function with multiple conditions
    const getScore = (text: string): number => {
      if (text === lowerFilter) return 0; // Exact match (highest score)
      if (text.startsWith(lowerFilter)) return 1; // Starts with match
      if (text.includes(lowerFilter)) return 2; // Contains match
      if (matchesAcronym(text, lowerFilter)) return 3; // Acronym match
      return 4 + levenshteinDistance(text, lowerFilter) / lowerFilter.length; // Fuzzy match based on distance
    };

    // Calculate scores
    const scoreA = getScore(aText);
    const scoreB = getScore(bText);

    // Sort by score
    return scoreA - scoreB;
  };

// Helper function to check acronym match
function matchesAcronym(text: string, filter: string): boolean {
  const words = text.split(' ');
  const initials = words.map((word) => word.charAt(0)).join('');
  return initials.startsWith(filter);
}

// Levenshtein distance function for fuzzy matching
function levenshteinDistance(a: string, b: string): number {
  const matrix = Array.from({ length: b.length + 1 }, (_, i) =>
    Array(a.length + 1)
      .fill(i)
      .map((v, j) => j || v)
  );

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1, // deletion
        matrix[i][j - 1] + 1, // insertion
        matrix[i - 1][j - 1] + (b[i - 1] === a[j - 1] ? 0 : 1) // substitution
      );
    }
  }
  return matrix[b.length][a.length];
}

const useMenuSearch = <Option extends IMenuItem | '-'>(
  options: Option[],
  opts?: {
    hiddenKeys?: MenuKey[];
    matchOptions?: (option: Option[], filter: string) => MenuKey[];
  }
): UseMenuSearch<Option> => {
  const { matchOptions = defaultMatchOptions, hiddenKeys } = opts || {};

  const [filter, setFilter] = useState<string | undefined>(undefined);

  const filteredKeys = useMemo<MenuKey[]>(() => {
    let filtered;
    try {
      filtered = filter?.trim().length ? matchOptions(options, filter) : [];
    } catch (e) {
      console.error(e);
    }

    return [...(hiddenKeys || []), ...(filtered || [])] as MenuKey[];
  }, [hiddenKeys, filter, options]);

  return {
    filter,
    filteredKeys,
    onFilterChange: setFilter,
    sort: sortByMatchPriority(filter) as (a: Option, b: Option) => number
  };
};

export { useMenuSearch, sortByMatchPriority };
