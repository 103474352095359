import { tv } from 'tailwind-variants';

export const checkStyles = tv({
  base: 'border rounded flex justify-center items-center text-white transition-all',
  variants: {
    size: {
      sm: 'w-4 h-4',
      md: 'w-5 h-5'
    },
    color: {
      primary: '',
      secondary: '',
      tertiary: '',
      neutral: ''
    },
    isSelected: {
      true: '',
      false: ''
    },
    isGroupHover: {
      true: '',
      false: ''
    },
    isDisabled: {
      true: 'pointer-events-none',
      false: 'cursor-pointer'
    }
  },
  compoundVariants: [
    {
      color: 'primary',
      isSelected: true,
      className: 'bg-primary-5 border-primary-5'
    },
    {
      color: 'primary',
      isSelected: false,
      className: 'bg-white hover:border-primary-5 group-hover/checkbox:border-primary-5'
    },
    {
      color: 'secondary',
      isSelected: true,
      className: 'bg-black border-black'
    },
    {
      color: 'secondary',
      isSelected: false,
      className: 'bg-white hover:border-black group-hover/checkbox:border-black'
    },
    {
      color: 'tertiary',
      isSelected: true,
      className: 'bg-black border-black'
    },
    {
      color: 'tertiary',
      isSelected: false,
      className: 'bg-neutral-1 border-black/10 hover:bg-neutral-2 group-hover/checkbox:bg-neutral-2'
    }
  ]
});
