import React, { ReactNode } from 'react';
import { ModalProps } from './types';
import { classNames } from '../../utils/classNames';

export interface ModalBodyProps {
  noPadding?: ModalProps['noPadding'];
  overflowHidden?: ModalProps['overflowHidden'];
  children: ReactNode;
}

export const ModalBody = React.forwardRef<HTMLDivElement, ModalBodyProps>((props, ref) => {
  const { noPadding, overflowHidden, ...rest } = props;
  return (
    <div
      ref={ref}
      className={classNames(
        'flex flex-col flex-1 w-full',
        noPadding ? '' : 'px-4 pb-4 pt-5 sm:pt-4 sm:pb-6 sm:px-6',
        overflowHidden ? 'sm:overflow-hidden' : ''
      )}
      {...rest}
    />
  );
});
