import { LegacyUiSize, UiSize } from '../../types';

const normalizedSizes: Record<LegacyUiSize, UiSize> = {
  xsmall: 'xs',
  small: 'sm',
  medium: 'md',
  large: 'lg',
  xlarge: 'xl'
};
export const normalizeSize = (size: LegacyUiSize | UiSize | undefined = 'md'): UiSize =>
  (normalizedSizes[size as LegacyUiSize] || size) as UiSize;
